.pricingContent {
  width: 60%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.PriHeadMain {
  font-size: 44px;
  font-weight: 700;
  color: #332c5c;
  font-family: "Nunito", sans-serif;
  text-align: center;
}

.preHeadPara {
  font-size: 20px;
  font-weight: 400;
  color: #4c4c4c;
  font-family: "Nunito", sans-serif;
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.pricingBiling {
  display: flex;
  gap: 20px;
  /* width: 40%; */
  /* margin-left: 50%; */
  /* transform: translateX(-50%); */
  align-items: center;
  text-align: center;
  justify-content: center;
}

.pricingBiling P {
  font-size: 20px;
  font-weight: 400;
  color: #4c4c4c;
  font-family: "Nunito", sans-serif;
}

.pricingCards {
  display: grid;
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-bottom: 80px;
}

.pricingCard {
  background-color: #ffffff;
  border: 2px solid #a0ecd0;
  border-radius: 10px;
  padding: 40px 20px;
  background: #ffffff;
  border: 2px solid #a0ecd0;
  box-shadow: -40px 60px 120px rgba(160, 236, 208, 0.2);
  border-radius: 15px;
}

.pricingCardHead {
  color: #090f4e;
  font-size: 28px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
}

.pricingCardPara {
  color: #63657e;
  font-size: 16px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
}

.pricingCardAmount {
  color: #090f4e;
  font-size: 42px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
}

.pricingCardTime {
  color: #63657e;
  font-size: 16px;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
}

.pricingCardFlexFirst {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.pricingAmountFlex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pricingAmountFlex p {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #90ebca;
}
.ant-switch.ant-switch-checked {
  background: #90ebca;
}

.PritickBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1fffa;
}

.pricingTickFlex {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.pricingTickFlex P {
  font-size: 16px;
  font-weight: 400;
  color: #63657e;
  font-family: "Nunito", sans-serif;
}

.pricingLine {
  width: 100%;
  height: 2px;
  background-color: #f5f6fa;
  margin: 24px 0px;
}

.activeBtnPricing {
  background: linear-gradient(180deg, #2e6073 0%, #214b5a 100%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 40px;
  width: 90px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  margin-top: 10px;
  width: 100%;
  color: #ffffff;
}
.pricingBtn {
  background: #a0ecd0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 40px;
  width: 90px;
  border: 2px solid #a0ecd0;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  margin-top: 10px;
  width: 100%;
  color: #2c2c2c;
}

.CTA-Bottom {
  text-align: center;
  font-size: 22px;
  margin-top: -40px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .pricingContent {
    width: 80%;
  }
  .PriHeadMain {
    font-size: 35px;
  }
}

@media screen and (max-width: 1080px) {
  .pricingCards {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 700px) {
  .pricingCards {
    grid-template-columns: 1fr;
  }
}

