.appMain h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.appMain p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
line-height: 25px;
}
