/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200&family=Nunito:wght@300&family=Open+Sans:wght@500&family=Poppins:wght@200&family=Roboto:wght@300&family=Source+Serif+Pro&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200&family=Nunito:wght@300&family=Open+Sans:wght@500&family=Poppins:wght@200&family=Roboto:wght@400;700&family=Source+Serif+Pro&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Tilt+Neon&display=swap");

* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style-type: none;
  font-family: "Tilt Neon", sans-serif !important;

}

button {
  cursor: pointer;
}

p {
  font-family: "Tilt Neon", sans-serif !important;
}

.formMessage{
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
}
