.companyMain h1 {
  visibility: hidden;
  color: #332c5c;
  font-family: 700;
  font-family: "Nunito", sans-serif;
  font-size: 44px;
  text-align: center;
}

.companyMain{
  visibility: hidden;
  padding: 40px 0px;
}

.companyMain p {
  color: #4c4c4c;
  visibility: hidden;
  font-size: 20px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  width: 60%;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.aboutGrid {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  gap: 20px;
  padding: 30px 0px;
}
.aboutGrid img {
  width: 200px;
}

@media screen and (max-width: 700px) {
  .aboutGrid {
    width: 50%;
  }
}
