.navbarBigMain {
  background-color: #a0ecd0;
}

.navBarIcons {
  /* width: 80%; */
  width: 70%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 36px;
}

.navbarImages {
  width: 95%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-bottom: 25px;
  /* padding-top: 100px; */
}

.navbarImg2 {
  /* position: relative;
  top: 40px;
  left: 20px;
  z-index: -2; */
  position: relative;
  top: -16px;
  left: 82px;
  z-index: -2;
  width: 160px;
}
.navbarImg1 {
  /* position: relative;
  top: -20px; */
  position: relative;
  top: -62px;
  width: 98px;
  left: 2%;
}
.navbarImg3 {
  /* position: absolute;
  right: 780px;
  top: 50px;
  width: 30px;
  z-index: -2; */
  position: absolute;
  right: 876px;
  top: -26px;
  width: 30px;
  z-index: -2;
  height: 289px;
}
.navbarImg7 {
  /* position: absolute;
  right: 300px;
  top: 30px;
  width: 150px;
  z-index: -2; */
  position: absolute;
  right: 300px;
  top: -38px;
  width: 150px;
  z-index: -2;
  height: 216px;
}
.navbarImg8 {
  /* position: absolute;
  top: 200px;
  z-index: -2; */
  position: absolute;
  z-index: -2;
  right: 59px;
  top: 93%;
}
.navbarImg9 {
  position: absolute;
  right: 10px;
  z-index: -2;
}
.navbarImg5 {
  /* position: relative;
  top: -70px;
  width: 180px;
  z-index: -2; */
  position: relative;
  top: -76px;
  width: 180px;
  z-index: -20;
  right: -92px;
}
.navbarImg6 {
  /* position: relative;
  top: 90px;
  width: 170px;
  z-index: -2; */
  position: relative;
  top: 71px;
  width: 170px;
  z-index: -2;
  right: -122px;
}
.navbarImg4 {
  /* position: relative;
  width: 200px;
  top: 70px;
  z-index: -2; */
  position: relative;
  width: 200px;
  top: 25px;
  z-index: -2;
  left: 35px;
}

.NavHeader {
  color: #11333f;
  font-size: 64px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  text-align: center;
  position: relative;
  top: -100px;
  font-weight: bolder;
}

.bar{
  position: relative;
  z-index: 200;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .navbarImg1 {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .navbarImg8,.navbarImg9 {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .navbarImg5 {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .navbarImg7 {
    display: none;
  }
  .NavHeader {
    font-size: 50px;
  }
}
@media screen and (max-width: 450px) {
  .navbarImg4 {
    position: absolute;
  }
  .NavHeader {
    font-size: 40px;
  }
}
