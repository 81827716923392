.useBigMain {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 60px;
}

.userBigHead {
  text-align: center;
}

.userBigPara {
  text-align: center;
  /* word-break: break-all; */
}

.useBigContent {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.useBigHead {
  font-size: 44px;
  font-weight: 700;
  color: #332c5c;
  font-family: "Nunito", sans-serif;
  text-align: center;
}

.useBigPara {
  font-weight: 400;
  font-size: 20px;
  color: #4c4c4c;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.useBoxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 24px;
}

.useBox {
  background-color: #ecfbf6;
  padding: 15px;
}

.useBox h1 {
  color: #090f4e;
  font-size: 20px;
  font-weight: 1000;
  font-family: "Roboto", sans-serif;
  /* font-family: "Poppins", sans-serif; */
  margin-bottom: 4px;
}

.useBullets {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.useBullet {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dot {
  height: 8px !important;
  width: 8px !important;
  border-radius: 50%;
  background: linear-gradient(180deg, #2e6073 0%, #214b5a 100%);
  display: none;
}

.useBullet {
  position: relative;
}

.spanDot {
  height: 8px !important;
  width: 8px !important;
  border-radius: 50%;
  background: linear-gradient(180deg, #2e6073 0%, #214b5a 100%);
  position: absolute;
  top: 8px;
}

.useText {
  /* word-break: break-all; */
}

.useBullet p {
  color: #63657e;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
}

.useBtn {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}

.useLine {
  width: 100%;
  z-index: -2;
  position: absolute;
}

@media screen and (max-width: 1000px) {
  .useBoxes {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .useBoxes {
    grid-template-columns: 1fr;
  }
}
