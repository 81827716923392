.projecrBigMain {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 100px;
}
.projectMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
}

.projectImage img {
  width: 100%;
  /* box-shadow: -20px 50px  80px rgba(0, 204, 179, 0.2); */

}

.projectContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}

.projectImage {
  position: relative;
}

.pLine {
  /* position: absolute;
  z-index: -2;
  top: 200px;
  left: -300px; */
  position: absolute;
z-index: -2;
top: 219px;
left: -315px;
height: 435px;
}

.projectMain {
  position: relative;
}

.projectVBar {
  right: 450px;
  position: absolute;
  top: -40px;
}

/* The Project Line */

@media screen and (max-width:1150px) {
  .pLine{
    position: absolute;
z-index: -2;
top: 198px;
left: -276px;
height: 435px;
  }
}
@media screen and (max-width:1020px) {
  .pLine{
    position: absolute;
z-index: -2;
top: 193px;
left: -242px;
height: 435px;
  }
}


/*   */

@media screen and (max-width: 1000px) {
  .projectMain {
    grid-template-columns: 1fr;
  }
  .projectVBar{
    display: none;
  }
}
