.integrationBigMain {
  width: 80%;
  transform: translateX(-50%);
  margin-left: 50%;
  margin-bottom: 131px;
}

.integrationMain {
  display: flex;
  gap: 70px;
}

.integrationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.integrationImage img {
  width: 100%;
  /* box-shadow: -20px 50px  80px rgba(0, 204, 179, 0.2); */
}

.integrationMain {
  position: relative;
}

.iBars {
  position: absolute;
  top: -70px;
  left: 170px;
  /* top: -300px; */
}

.integrationImage {
  position: relative;
}

.iLine {
  /* position: absolute;
  top: 300px;
  left: 300px;
  z-index: -2; */
  /* position: absolute;
bottom: -277px;
right: 384px;
z-index: -2;
width: 400px; */
  position: absolute;
  bottom: -302px;
  right: -328px;
  z-index: -2;
}

.integrationContent {
  width: 100%;
  flex: 1;
}

.integrationImage {
  flex: 1;
}

/* The Line Styles */

@media screen and (max-width: 1200px) {
  .iLine {
    position: absolute;
    bottom: -300px;
    right: -280px;
    z-index: -2;
  }
}
@media screen and (max-width: 1120px) {
  .iLine {
    position: absolute;
    bottom: -244px;
    right: -253px;
    z-index: -2;
  }
}
@media screen and (max-width: 1038px) {
  .iLine {
    position: absolute;
    bottom: -193px;
    right: -253px;
    z-index: -2;
  }
}

/*  */

@media screen and (max-width: 1000px) {
  .integrationMain {
    /* grid-template-columns: 1fr; */
    flex-direction: column-reverse;
  }
  .integrationBigMain {
    margin-top: 200px;
  }
  .iBars {
    display: none;
  }
}
