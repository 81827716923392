.snapShotBigMain {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.snapShopMain {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  gap: 70px;
}

.snapShotContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.snapShotImage {
  position: relative;
}

.snapShotContent{
  width: 100%;
}

.snapShopMain {
  position: relative;
}



.snapMiddleBar {
  position: absolute;
  width: 100px;
  position: absolute;
  top: 140px;
  left: -30px;
  z-index: -5;
}

.snapTopBar {
  /* height: 100px; */
  width: 100px;
  position: absolute;
  top: -50px;
  left: 140px;
  /* z-index: -5; */
}


.snapShotContent{
  flex: 1;
}

.snapShotImage{
  flex: 1;
  height: 100%;
  width: 100%;
}


@media screen and (max-width: 1000px) {
  .snapShopMain {
    /* grid-template-columns: 1fr; */
    flex-direction: column-reverse;
  }
  .snapMiddleBar{
    display: none;
  }
  .snapTopBar{
    display: none;
  }
}
