.pagesWrapper h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.pagesWrapper h2{
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

.pagesWrapper p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
