.customerBigMain {
  width: 80%;
  transform: translateX(-50%);
  margin-left: 50%;
  margin-bottom: 150px;
}

.customerMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
}

/* The Common CSS */

.homeHead {
  color: #332c5c;
  font-family: "Nunito", sans-serif;
  font-size: 44px;
  font-weight: 700;
}

.homePara {
  color: #4c4c4c;
  /* font-family: "Nunito", sans-serif; */
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.homeBtnCommon {
  background: linear-gradient(180deg, #2e6073 0%, #214b5a 100%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 40px;
  width: 90px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  margin-top: 10px;
}

.customerContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
.customerImages {
  flex: 1;
}

.customerContent {
  flex: 1;
}

.customerImages .mainImg {
  width: 100%;
  box-shadow: -20px 50px 80px rgba(0, 204, 179, 0.2);
}

.customerImages {
  position: relative;
}
.customPosition {
  position: relative;
}

.customerBarsMainImage {
  position: absolute;
  z-index: -5;
  right: -160px;
  top: 150px;
}

.customerImages {
  position: relative;
}

.customerBars {
  position: absolute;
  left: -20px;
  top: -40px;
  z-index: -5;
}

.customerBarsBotton {
  position: absolute;
  right: -90px;
  bottom: -40px;
  z-index: -5;
}

.cLine {
  position: absolute;
  bottom: -316px;
  right: 362px;
  z-index: -2;
}

/* The Line Css */

@media screen and (max-width: 1280px) {
  .cLine {
    position: absolute;
    bottom: -344px;
    right: 327px;
    z-index: -2;
  }
}
@media screen and (max-width: 1216px) {
  .cLine {
    position: absolute;
    bottom: -355px;
    right: 279px;
    z-index: -2;
  }
}
@media screen and (max-width: 1100px) {
  .cLine {
    position: absolute;
    bottom: -345px;
    right: 233px;
    z-index: -2;
  }
}
@media screen and (max-width: 1034px) {
  .cLine {
    position: absolute;
    bottom: -345px;
    right: 233px;
    z-index: -2;
  }
}
@media screen and (max-width: 1008px) {
  .cLine {
    position: absolute;
    bottom: -290px;
    right: 232px;
    z-index: -2;
  }
}

/*  */

@media screen and (max-width: 1000px) {
  .customerMain {
    grid-template-columns: 1fr;
  }
}
