.nav-main {
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  padding: 30px 0px;
}

/* The Big Screen Navbar */
.nav {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.nav-front h2 {
  letter-spacing: 10px;
}

.nav-main nav ul {
  display: flex;
  gap: 1rem;
}

.nav-main nav ul li a {
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  line-height: 32px;
}

.nav-main nav ul li a:hover {
  scale: 1.1;
}

.logo {
  height: 60px;
}

/* The navBtns */
.navBtnsBigScreen {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navBtnsSmallScreen {
  display: flex;
  align-items: center;
  display: none;
  margin-left: 20px;
  gap: 20px;
}

.navBtnsSmallScreen .signUp {
  margin-left: 20px;
}

.login {
  height: 35px;
  width: 90px;
  border-radius: 20px;
  background-color: black;
  color: #ffffff;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.signUp {
  border: 1px solid #0065fd;
  height: 35px;
  width: 90px;
  border-radius: 20px;
  color: #ffffff;
  background-image: linear-gradient(#02cb46, #56638b);
  cursor: pointer;
}

/* Small Screen nav starts */
.smal-screen-nav nav ul {
  display: flex;
  flex-direction: column;
}
.big-screen-nav ul li a {
  color: #ffffff;
  font-family: "Nunito", sans-serif;
}

.big-screen-nav ul li a:hover {
  color: #0065fd;
}

.smal-screen-nav {
  position: fixed;
  background: red;
  right: 0;
  width: 297px;
  z-index: 30;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  top: 0;
  height: 100vh;
  bottom: 0;
  transform: translateX(350px);
  transition: 1s ease-in-out;
  text-decoration: none;
  list-style: none;
}

.small-screen-nav-active {
  transform: translateX(0px);
}

.smal-screen-nav nav ul li a {
  color: #ffffff;
  font-size: 25px;
  font-family: "Nunito", sans-serif;
}

@media screen and (max-width: 600px) {
  .smal-screen-nav nav ul li a {
    font-size: 20px;
  }
}

.smal-screen-nav nav ul li a:hover {
  color: #a0ecd0;
}

.smal-screen-nav nav ul {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.smal-screen-nav nav {
  padding: 0px 30px;
  margin-top: 40px;
}

.small-screen-close {
  font-size: 41px;
  position: absolute;
  right: 37px;
  top: 32px;
  color: white;
  cursor: pointer;
}

.ham {
  display: none;
  font-size: 40px;
  cursor: pointer;
}

.navBarLogo {
  height: 40px;
  width: 150px;
}

.signUp {
  background-color: #0065fd;
}

.ham {
  color: white;
}

.fancy-link {
  text-decoration: none;
  position: relative;
  padding-bottom: 10px;
}

.fancy-link:hover {
  scale: 1.1;
}

.fancy-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 10;
  width: 100%;
  height: 2px;
  background-color: #a0ecd0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform 250ms ease-in;
  transition: transform 250ms ease-in;
  transition: transform 250ms ease-in, -webkit-transform 250ms ease-in;
  margin-top: 20px;
}

.fancy-link:hover::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
}

@media screen and (max-width: 1200px) {
  .big-screen-nav {
    display: none;
  }
  .ham {
    display: block;
  }
  .smal-screen-nav {
    display: block;
  }
  .navBtnsBigScreen {
    display: none;
  }
  .navBtnsSmallScreen {
    display: block;
    gap: 20px;
  }
}

.fancyDectoration {
  text-decoration: none;
  position: relative;
  padding-bottom: 10px;
}

.fancyDectorationTrading {
  text-decoration: none;
  position: relative;
  padding-bottom: 10px;
}

.fancyDectoration::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 10;
  width: 100%;
  height: 2px;
  background-color: #a0ecd0;

  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform 250ms ease-in;
  transition: transform 250ms ease-in;
  transition: transform 250ms ease-in, -webkit-transform 250ms ease-in;
  margin-top: 20px;
}

.fancyDectoration::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
  width: 40%;
}

.fancyDectorationTrading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 10;
  width: 100%;
  height: 2px;
  background-color: #a0ecd0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform 250ms ease-in;
  transition: transform 250ms ease-in;
  transition: transform 250ms ease-in, -webkit-transform 250ms ease-in;
  margin-top: 20px;
}

.fancyDectorationTrading::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
  width: 4%;
}

.sidebarIcon {
  margin-top: 30px;
  margin-left: 30px;
}

.nav {
  position: relative;
}

.close {
  position: absolute;
  right: 20px;
  top: 27px;
  font-size: 45px;
  color: #a0ecd0;
  cursor: pointer;
}
