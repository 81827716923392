.aboutBigMain {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  padding: 150px 0px;
}
.foundersBio{
  text-align: center;
}
.aboutContent h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #332c5c;
  font-family: "Nunito", sans-serif;
}
.aboutContent h2 {
  margin-bottom: 10px;
  color: #332c5c;
  font-family: "Nunito", sans-serif;
}
.aboutContent h3 {
  margin-bottom: 10px;
  color: #332c5c;
  font-family: "Nunito", sans-serif;
}

.aboutContent p {
  color: #4c4c4c;
  font-family: "Nunito", sans-serif;
  font-size: 20px;;
}

.aboutImageWrapper {
  /* background-color: red; */
  position: relative;
}

.aboutImage img {
  width: 100%;
}

.aboutBase {
  height: 80%;
  width: 100%;
}
.aboutImageWrapper .aboutImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10%;
  left: 0px;
}

.aboutVBar {
  position: absolute;
  bottom: -3%;
  right: 2%;
  z-index: -2;
}

.aboutHbar {
  position: absolute;
  left: -10%;
  top: -15%;
  z-index: -2;
}

@media screen and (max-width: 1100px) {
  .aboutBigMain {
    grid-template-columns: 1fr;
  }
  .aboutImageWrapper {
    margin-top: 60px;
  }
}

