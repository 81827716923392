.mapMain {
  background-color: #ecfbf6;
  padding: 30px 0px;
}

.mapMain h2 {
  text-align: center;
  width: 70%;
  margin-left: 50%;
  transform: translateX(-50%);
  color: #332c5c;
  /* font-family: "Poppins", sans-serif; */
  /* margin-top: 80px; */
  /* font-family: 'Nunito', sans-serif; */
  font-size: 44px;
  font-weight: 600;
}
.mapImage {
  width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}
.mapImage img {
  width: 100%;
}

.mapImage {
  position: relative;
}

.mapMain {
  position: relative;
}

.c1 {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 25%;
  left: 12%;
}
.c2 {
  position: absolute;
  height: 30px;
  width: 30px;
  /* top: 30%;
    left: 37%; */
  top: 9%;
  left: 34%;
}
.c3 {
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 38%;
  left: 26%;
}
.c4 {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 24%;
  right: 49%;
}
.c5 {
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 29%;
  right: 41%;
}
.c6 {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 37%;
  right: 26%;
}
.c7 {
  position: absolute;
  width: 20px;
  right: 11%;
  top: 21%;
}
.c8 {
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 21%;
  right: 12%;
}

.mapImageWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapImageWrapper img {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 700px) {
  .mapImageWrapper {
    width: 10px;
    height: 10px;
  }
  .c1,
  .c2,
  .c3,
  .c4,
  .c5,
  .c6,
  .c7,
  .c8 {
    width: 5px;
    height: 5px;
  }
}


@media screen and (max-width:900px) {
  .mapMain h2{
    font-size: 30px;
  }
}

@media screen and (max-width:600px) {
  .mapMain h2{
    font-size: 25px;
  }
}

@media screen and (max-width:470px) {
  .mapMain h2{
    font-size: 20px;
  }
}